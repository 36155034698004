import * as React from 'react';
import DisplayDataPaper from "../_displayDataPaper";

import { ReactComponent as FileIcon } from "../../../assets/jobIcons/file.svg";
import DisplayDataTable from "../_displayDataTable";
import PaginationData, { Pagination } from "../PaginationData";
import { useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { Typography } from '@mui/material';

const columnsOrder = ["dateTime", "description", "amount", "currencyAmount", "balance"]
const columnsNames = ["Date & Time", "Description", "Amount", "Currency Amount", "Balance"];

type TransactionType = "top_up" | "payout" | "payment" | "reward" | "refund" | "fee" | "gift" | "coupon";

export interface TransactionHistoryRecord {
  dateTime: string;
  id: string;
  description: string;
  amount: number;
  currencyAmount: number;
  balance: number;
  transactionType?: TransactionType;
}

export interface TransactionHistoryPaperProps {
  transactions: any[];
  //isLoading : boolean;
  setTransactionsFilters?: any;
  accountId: string;
  balance: number;
  pageSize?: number
}

const parseTransactionsHistoryRecords = (transactions: any[], accountId: string, balance: number) => {
  let totBalance = balance;
  let amount = 0;
  const parsedTransactions: TransactionHistoryRecord[] = transactions.map(t => {
    const description = t.description
    // const positive = t.accountTo === accountId;
    totBalance -= amount; //we use it as previous amount
    amount = t.amount;
    const currencyAmount = t.relatedEntityData?.currencyAmount || 0;

    // amount = +t.amount * (positive ? 1 : -1);
    // let description = positive ? "Top up" : "Job"
    // switch (t.transactionType) {
    //   case "gift":
    //     description = t.description
    //     break
    //   case "coupon":
    //     description = t.description
    //     break
    //   case "reward":
    //     description = t.description
    //     break
    //   case "refund":
    //     description = t.description
    //     break
    //   case "payout":
    //     description = t.description
    //     break
    //   case "subscription":
    //     description = 'Subscription'
    //     break
    // }
    return {
      dateTime: t.createdAt ? (t.createdAt.split("T")[0] + ", " + t.createdAt.split("T")[1].split(".")[0]) : "",
      id: t.id,
      description: description,
      amount: amount,
      currencyAmount: currencyAmount,
      balance: totBalance
    }
  }
  );
  return parsedTransactions
}

const TransactionHistoryPaper = ({ ...props }: TransactionHistoryPaperProps) => {
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const { transactions, accountId, balance } = props;
  const [offset, setOffset] = useState(0)
  // const [limit, setLimit] = useState(10)

  const handlePageNumberClick = (page: number) => {
    setOffset((page - 1) * currentPageSize)
  }

  const paginationData: Pagination = {
    hasNext: offset <= (transactions.length + currentPageSize),
    hasPrevious: offset > 0,
    offset: offset,
    total: transactions.length
  }

  const handleCurrentPageSize = (e: SelectChangeEvent<number>) => {
    setCurrentPageSize(e.target.value as number)
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <DisplayDataPaper
        title={"Transaction history"}
        style={{ height: '100%' }}
        headerActions={
          <Typography display={"flex"} gap={2} alignItems={"center"} marginTop={-1}>
            <p>Show</p>
            <FormControl fullWidth>
              <Select
                defaultValue={10}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
                value={currentPageSize}
                label="Age"
                onChange={handleCurrentPageSize}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Typography>
        }
        children={
          <DisplayDataTable
            currentPageSize={currentPageSize}
            data={parseTransactionsHistoryRecords(transactions, accountId, balance)}
            columnsNames={columnsNames}
            columnsOrder={columnsOrder}
            columnsSortable={["dateTime", "amount", "currencyAmount", "balance"]}
            transactionColumns={["amount"]}
            iconNoData={<FileIcon></FileIcon>}
            displayTableIfNoData={true}
            messageNoData={"No transactions so far"} pagination={paginationData} />
        }
      ></DisplayDataPaper>
      <PaginationData limit={currentPageSize} pagination={paginationData} onPageNumberClick={handlePageNumberClick} />
    </Box>
  );
}

export default TransactionHistoryPaper
